import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { SideMenuProps } from './model';

import './SideMenu.scss';

const SideMenu: FC<SideMenuProps> = ({ menuItems, activeId, menuTitle }) => (
  <nav className="side-menu">
    <h2 className="side-menu__header">{menuTitle}</h2>
    {menuItems.map(({ link, title: name, id }) => (
      <Link to={link} key={id}>
        <div
          className={classNames('side-menu__item', {
            'side-menu__item--active': id === activeId,
          })}
          data-testid="menu-item"
        >
          {name}
        </div>
      </Link>
    ))}
  </nav>
);

export default SideMenu;
